import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'

const CdekIntegration: FC = () => {
  return (
    <div className="relative text-base text-black bg-gradient-to-tr from-[#F5F6F7] to-[#F5F6F7] selection:bg-gray-200">
      <div className="absolute hidden lg:flex right-16 items-center justify-center inset-y-0">
        <img src="/next/img/landing/cdek.png" alt="CDEK" className="w-[283px] h-[230px]" />
      </div>
      <div className="w-full px-6 pt-12 mx-auto max-w-7xl sm:px-16 pb-14">
        <h2 className="text-2xl font-medium">Доверьте CDEK логистику до маркетплейсов</h2>
        <div className="mt-4 space-y-0.5 max-w-[70ch]">
          <p>
            FBO и FBS, удобная форма заказа, сдача груза в ПВЗ или курьеру,
            <br className="hidden lg:block" /> услуги маркировки и переупаковки, хранение груза для
            доставки в нужный день
          </p>
        </div>
        <Button
          as="a"
          href="https://www.cdek.ru/ru/marketplace/?utm_source=sellmonitor&utm_medium=site&utm_campaign=banner"
          target="_blank"
          rel="noreferrer"
          theme="primary"
          className="!bg-[#1DB248] hover:brightness-105 focus:ring-offset-gray-900 focus:ring-[#1DB248] mt-8"
        >
          Заключить договор
        </Button>
      </div>
    </div>
  )
}

export default CdekIntegration
