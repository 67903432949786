import Button from 'components/base/Button'
import { FC } from 'lib/component-utils'
import Link from 'components/base/Link'

const ConsultingBanner: FC = () => {
  return (
    <div className="relative text-base text-black bg-gradient-to-tr from-primary-50 via-primary-100 to-primary-200">
      <div className="px-6 pt-12 pb-14 mx-auto w-full max-w-7xl sm:px-16">
        <h2 className="text-2xl font-medium">Нужна помощь эксперта?</h2>
        <div className="mt-4 space-y-0.5">
          <p className="max-w-[85ch]">
            Персональный аналитик Sellmonitor поможет с любыми вопросами. Аудит магазина, план
            развития и полная поддержка вашего бизнеса с регулярными встречами. Подписка на сервис в
            подарок.
          </p>
        </div>
        <div className="flex items-center mt-8 space-x-4">
          <Button
            as={Link}
            target="_blank"
            rel="noreferrer"
            href="/consulting/"
            theme="primary"
            className="w-fit h-[42px]"
          >
            Узнать больше
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConsultingBanner
