import { Message } from 'ai'
import { atomWithStorage as atom } from 'jotai/utils'
import {
  BusinessSalesPeriodFilter,
  BusinessSalesProductFilter,
  BusinessReviewsOnboarding,
  SellersWorkScheme,
  ISO8601DateString,
  BusinessSalesTableRow,
} from 'lib/dto'
import { AvailableModel } from './ai/openai'
import { MallCode } from 'configs/malls'

export type LocalStorage = {
  oldBrowserWarningShown: boolean
  'yandexAdClosed:malls': boolean
  'yandexAdClosed:productList': boolean
  'brandMonitorAdClosed:productList': boolean
  'cdekAdClosed:productList': boolean
  'consultingAdClosed:productList:times': number
  'consultingAdClosed:productList:date': ISO8601DateString
  sidebarSize: 'large' | 'compact' | 'auto'
  'analyticsPeriodFilter:wb': BusinessSalesPeriodFilter
  'analyticsProductFilter:wb': BusinessSalesProductFilter
  'analyticsSellersWorkScheme:wb': SellersWorkScheme
  'analyticsPeriodFilter:ozon': BusinessSalesPeriodFilter
  'analyticsProductFilter:ozon': BusinessSalesProductFilter
  'analyticsSellersWorkScheme:ozon': SellersWorkScheme
  reviewsOnboarding: BusinessReviewsOnboarding
  assistantHistory: Message[]
  assistantModel: AvailableModel
  guideGroupsState: Record<string, boolean>
  primeCostModalSettings: {
    fileType: 'bySkus' | 'bySupplierSkus' | 'byBarcodes'
    withSizes: boolean
  }
  comparisonProductIds: Record<MallCode, string[]>
  businessProductListVisibleColumns: string[]
  seasonalityLabeledCount: number
  categoriesInventoryType: 'merchant' | 'marketplace' | null
}

export const storage = {
  get: <T extends keyof LocalStorage>(key: T): LocalStorage[T] | null => {
    const value = localStorage.getItem(key)
    if (value === null) return null
    return JSON.parse(value)
  },

  set: <T extends keyof LocalStorage>(key: T, value: LocalStorage[T]) => {
    const storedValue = JSON.stringify(value)
    localStorage.setItem(key, storedValue)
  },

  remove: <T extends keyof LocalStorage>(key: T) => {
    localStorage.removeItem(key)
  },
}

export const atomWithStorage = <T extends keyof LocalStorage>(key: T, value: LocalStorage[T]) => {
  return atom(key, value)
}
