import { FC } from 'lib/component-utils'
import * as amplitudeLocal from '@amplitude/analytics-browser'
import { useUserContext } from 'lib/context'

export const amplitude = amplitudeLocal

const Amplitude: FC = () => {
  const ctx = useUserContext()

  amplitudeLocal.init('a135f473f949bf804e87a132f9d5d4f9', {
    autocapture: true,
    serverZone: 'EU',
    minIdLength: 1,
  })

  if (!ctx.isAuthorized) return null

  const identify = new amplitudeLocal.Identify()

  amplitudeLocal.setUserId(ctx.user.id.toString())

  identify.set('email', ctx.user.email)
  identify.set('phone', ctx.user.phone)
  identify.set('name', ctx.user.fullName ?? '—')
  identify.set('roles', ctx.user.roles)
  identify.set('isActive', ctx.user.active)
  identify.set('activeUntil', ctx.user.activeUntil ?? '—')
  identify.set('activeMalls', ctx.user.activeMalls)
  identify.set('site', ctx.theme.hostType)

  amplitudeLocal.identify(identify)

  return null
}

export default Amplitude
